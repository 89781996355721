import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import { env } from '@/shared/firebase'

const CalculateCatalog = (catalog, options) => {
  options = {
    flat: false,
    categoryId: null,
    products: null,
    hideEmptyCategories: false,
    priceField: 'retailPrice',
    ...options
  }

  const { flat, categoryId, products, hideEmptyCategories, priceField } = options

  const categories = calculateCategories(catalog.categories, products)

  let data = {
    categories,
    breadcrumbs: []
  }

  if (hideEmptyCategories) data.categories = removeEmptyCategories(JSON.parse(JSON.stringify(data.categories)))

  if (categoryId) {
    const breadcrumbs = []
    let category = { categories: data.categories }
    for (let i = 1; i <= categoryId.length; i++) {
      category = category.categories[categoryId.substr(0, i)]
      breadcrumbs.push({
        label: category.label,
        id: categoryId.substr(0, i)
      })
    }
    data = {
      ...category,
      breadcrumbs
    }
  }

  if (flat) data.categories = getFlatCategories({ categories })

  return { ...data, items: calculateItems(data, priceField) }
}

export { CalculateCatalog }

const orderedObj = object => {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
}

const calculateCategories = (categories, products) => {
  // filteredProducts is a fix for weird bug leading to duplicated entries after change in firestore
  // const filteredProducts = []
  // products.forEach(product => {
  //   const index = filteredProducts.findIndex(p => p.id === product.id)
  //   if (index === -1) {
  //     filteredProducts.push(product)
  //   } else {
  //     filteredProducts[index] = product
  //   }
  // })
  const calculatedCategories = {}
  Object.keys(categories).map(categoryId => {
    const category = categories[categoryId]
    if (products)
      category.products = products
        .filter(p => p.categoryId === categoryId)
        .sort((a, b) => {
          return a.order - b.order
        })
    if (category.categories) category.categories = calculateCategories(category.categories, products)
    calculatedCategories[categoryId] = category
  })
  return orderedObj(calculatedCategories)
}

const getFlatCategories = catalog => {
  const categories = []
  const getCategories = catalog => {
    Object.keys(catalog.categories).forEach(categoryId => {
      const category = catalog.categories[categoryId]
      categories.push({
        label: category.label,
        id: categoryId,
        products: category.products
      })
      if (category.categories) getCategories({ categories: category.categories })
    })
  }
  getCategories(catalog)
  return orderBy(categories, ['id'])
}

const removeEmptyCategories = categories => {
  const checkCategory = categoryId => {
    let category = { categories }
    for (let i = 1; i <= categoryId.length; i++) {
      category = category.categories[categoryId.substr(0, i)]
    }
    if (category.categories) {
      Object.keys(category.categories).forEach(categoryId => {
        checkCategory(categoryId)
      })
    }
    if (category.products.length === 0 && isEmpty(category.categories)) {
      const path = []
      for (let i = 1; i <= categoryId.length; i++) {
        path.push(categoryId.substr(0, i))
      }
      if (path.length === 1) delete categories[path[0]]
      if (path.length === 2) delete categories[path[0]].categories[path[1]]
      if (path.length === 3) delete categories[path[0]].categories[path[1]].categories[path[2]]
    }
  }
  Object.keys(categories).map(categoryId => {
    checkCategory(categoryId)
  })
  return categories
}

const calculateItems = (catalog, priceField) => {
  const items = []
  if (catalog.products?.length > 0) {
    catalog.products.forEach(product => {
      items.push({
        label: `${product.brandName} ${product.label}`,
        params: { categoryId: product.categoryId, productId: product.id },
        img: `https://storage.googleapis.com/${env === 'production' ? 'emma-static' : 'emma-static-dev'}/products/${
          product.img
        }_256.jpg`,
        retailPrice: product[priceField]
      })
    })
  } else if (Object.keys(catalog.categories).length > 0) {
    Object.keys(catalog.categories).forEach(categoryId => {
      const category = catalog.categories[categoryId]
      items.push({
        label: category.label,
        params: { categoryId: categoryId },
        img: `https://storage.googleapis.com/emma-static/categories/${category.img}.jpg`
      })
    })
  }
  return items
}
